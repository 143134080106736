@if (!navigateBack && breadcrumbs) {
  <div class="myt-breadcrumb hidden md:flex md:items-center">
    <myt-icon [routerLink]="homeLink" class="myt-breadcrumb__home-icon" name="home" [size]="iconSize.Small" />
    <myt-icon name="keyboard_arrow_right" class="myt-breadcrumb__next-icon" [size]="iconSize.ExtraSmall" [type]="iconType.Neutral" />

    @for (breadcrumbItem of breadcrumbs; track breadcrumbItem.url; let last = $last) {
      @if (last) {
        <span class="myt-breadcrumb__current">{{ breadcrumbItem.title | breadcrumb }}</span>
      } @else {
        <a
          class="myt-breadcrumb__previous"
          href="javascript:void(0)"
          (click)="handleBreadcrumbItemClick(breadcrumbItem)"
          [ngClass]="{ 'myt-breadcrumb__previous--no-link': !isHistoryExist && !breadcrumbItem.url }"
          >{{ breadcrumbItem.title }}</a
        >
        <myt-icon name="keyboard_arrow_right" class="myt-breadcrumb__next-icon" [size]="iconSize.ExtraSmall" [type]="iconType.Neutral" />
      }
    }
  </div>

  <div class="md:hidden">
    <ng-container *ngTemplateOutlet="backButtonTemp" />
  </div>
}

@if (navigateBack) {
  <ng-container *ngTemplateOutlet="backButtonTemp" />
}

<ng-template #backButtonTemp>
  <div class="myt-breadcrumb flex items-center">
    <myt-icon name="keyboard_arrow_left" class="myt-breadcrumb__next-icon text-color" [size]="iconSize.Small" />
    <span class="myt-breadcrumb__previous" (click)="back()"> {{ 'PLATFORM.BACK' | translate }}</span>
  </div>
</ng-template>
