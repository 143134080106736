import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, booleanAttribute, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { IconSize } from '../icon/icon-size.enum';
import { IconType } from '../icon/icon-type.enum';
import { IconsComponent } from '../icon/icon.component';
import { Breadcrumb } from './breadcrumb.interface';
import { BreadCrumbPipe } from './breadcrumb.pipe';

@Component({
  selector: 'myt-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrl: './breadcrumb.component.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterModule, IconsComponent, TranslateModule, NgClass, NgTemplateOutlet, BreadCrumbPipe],
})
export class BreadcrumbComponent {
  @Input() breadcrumbs?: Breadcrumb[];
  @Input() currentTitle: string;
  @Input() homeLink = 'home';
  @Input({ transform: booleanAttribute }) navigateBack: boolean;
  readonly iconSize = IconSize;
  readonly iconType = IconType;

  get isHistoryExist() {
    return history.length > 2;
  }

  handleBreadcrumbItemClick(item: Breadcrumb) {
    if (item.useBrowserHistory) {
      const index = this.breadcrumbs?.indexOf(item) as number;
      const historyLevel = (this.breadcrumbs?.length as number) - (index + 1);
      if (history.length >= historyLevel) {
        history.go(-historyLevel);
      }
    } else if (item.url) {
      const router = inject(Router);
      router.navigate([item.url]);
    }
  }

  back(): void {
    window.history.back();
  }
}
