import { inject, Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';

@Pipe({
  name: 'breadcrumb',
  standalone: true,
})
export class BreadCrumbPipe implements PipeTransform {
  private readonly route = inject(ActivatedRoute);

  transform(breadCrumbTitle: string): string {
    if (breadCrumbTitle?.startsWith(':')) {
      const paramWithoutColon = breadCrumbTitle.slice(1);
      return this.getRouteParams(this.route.snapshot)[paramWithoutColon];
    }

    return breadCrumbTitle;
  }

  private getRouteParams(snapshot: ActivatedRouteSnapshot): Record<string, string> {
    let data = { ...snapshot.params };
    if (snapshot.firstChild) {
      data = { ...this.getRouteParams(snapshot.firstChild) };
    }
    return data;
  }
}
